@import 'src/styles/common';

.loader {
  .loading-text {
    margin-top: 32rem;
    font-size: 2rem;
  }

  .loader-container {
    .loader-blur {
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(rem(12));
    }

    .logo {
      height: rem(124);
      width: rem(102);
    }

    .company-name {
      font-size: 2rem;
      line-height: rem(39);
      text-align: center;
      margin-top: 1rem;
    }
  }
}
