@import '../../../styles/common';


.H1 {
  font-size: rem(70);
  line-height: rem(57);
  font-weight: $font-heavy;
}

.H2 {
  font-size: rem(48);
  line-height: rem(57);
  font-weight: $font-heavy;
}

.H3 {
  font-size: rem(28);
  line-height: rem(34);
  font-weight: $font-heavy;
}

.H4 {
  font-size: rem(24);
  line-height: rem(29);
  font-weight: $font-medium;
}

.H5 {
  font-size: rem(18);
  line-height: rem(16);
  font-weight: $font-medium;
}

.H6 {
  font-size: rem(16);
  line-height: rem(19);
  font-weight: $font-heavy;
}

.H7 {
  font-size: rem(16);
  line-height: rem(19);
  font-weight: $font-medium;
}

.H8 {
  font-size: rem(14);
  line-height: rem(17);
  font-weight: $font-regular;
}
