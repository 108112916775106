// Use tint and shade instead of brighten and darken
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Helpers
@function stripUnit($number) {
  @return $number / ($number * 0 + 1);
}

// Use these instead of pixels
@function em($size, $inherited-size) {
  @return stripUnit($size) / stripUnit($inherited-size) * 1em;
}

@function rem($size) {
  @return stripUnit($size) / 16 * 1rem;
}
