@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './common';
@import './bootstrap';
@import './zoom_image.css';

html {
  margin: 0;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;

  body {
    font-size: inherit;
  }
}

:root {
  --PhoneInputCountryFlag-height: 1.5rem;
}

.PhoneInputCountry {
  padding: 0 10px;
}
