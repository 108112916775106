$color-primary: #ef8c3b;
$color-secondary-light: #cbf2ff;
$color-secondary-dark: #39c4ef;

$color-success: #77b800;
$color-warning: #ffb200;
$color-error: #f31616;

$color-pending: #257791;

$color-darkText: #191919;
$color-base2: #58585a;
$color-gray44: #707070;
$color-mischka: #a8a9ad;
$color-gray86: #dbdbdb;
$color-gray100: #f2f2f2;

$color-lightBlue: #fbfeff;

$color-rejected: #af001f;

$font-regular: 400;
$font-medium: 700;
$font-heavy: 900;

$nav-item-background: transparent
  linear-gradient(270deg, #ffffff 0%, $color-secondary-light 100%) 0% 0%
  no-repeat padding-box;

$invite-renter-width: 556 / 16 * 1rem;
$edit-unit-width: 556 / 16 * 1rem;
