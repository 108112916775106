@import 'src/styles/common';

.onboarding-page {
  .overview-container {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .image-container {
    margin-left: 7rem;
    width: 40%;
  }

}
