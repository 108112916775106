@import '../../../styles/common';

.mt-button {
  .btn {
    border-radius: 0.625rem;

    &:disabled,
    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      margin-top: 2px;
    }
  }

  .primary {
    background-color: $color-secondary-dark;
    border-color: $color-secondary-dark;
  }

  .secondary {
    color: $color-darkText;
    background-color: $color-secondary-light;
    border-color: $color-secondary-light;
  }

  .login {
    background-color: $color-pending;
    border-color: $color-pending;
  }

  .accept {
    background-color: $color-success;
    border-color: $color-success;
  }

  .reject {
    background-color: $color-rejected;
    border-color: $color-rejected;
  }

  .edit {
    background-color: $color-warning;
    border-color: $color-warning;
  }

  .misc {
    background-color: $color-gray86;
    border-color: $color-gray86;
    color: $color-darkText;
  }

  .unit-secondary {
    background-color: white;
    border-color: $color-base2;
    color: black !important;
    font-weight: normal !important;
  }
}

.card-width {
  width: calc(100% - 16px);
}
