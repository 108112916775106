@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~react-image-gallery/styles/scss/image-gallery.scss';

#google_element {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 28px;
  overflow: hidden;

  span {
    display: none;
  }
}

.cursor-not-allowed-important {
  @apply cursor-not-allowed #{!important};
}

.ant-upload-list {
  padding-right: 10px;
}

.ant-upload-picture-card-wrapper {
  @apply mt-0 #{!important};
}